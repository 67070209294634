<template>
  <div>
    <MultiSelect
      v-if="!!relationInfo && !!relatedCollection"
      optionValue="value"
      optionLabel="label"
      dataKey="value"
      :options="options"
      :virtualScrollerOptions="virtualScrollerOptions"
      :editable="false"
      :tabindex="props.column.fieldInfo.meta.sortPosition"
      :aria-label="getFieldLabel(props.column.fieldInfo)"
      :class="['w-full']"
      inputClass="form-select"
      :modelValue="props.filter.value ?? []"
      display="chip"
      :loading="isRelatedItemsLoading"
      :placeholder="isRelatedItemsLoading ? $t('items_is_loading') : ''"
      :disabled="isRelatedItemsLoading || props.column.fieldInfo.meta.isReadonly"
      @update:modelValue="onUpdateModelValue"
      v-on="dropdownEventListeners"
    >
      <template #header>
        <div class="p-dropdown-header">
          <div class="p-dropdown-filter-container">
            <input
              type="text"
              autocomplete="off"
              role="search"
              :value="search"
              :class="['p-dropdown-filter', 'p-inputtext', 'p-component']"
              v-on="searchEventListeners"
            />
            <span :class="['p-dropdown-filter-icon', 'pi pi-search']"></span>
          </div>
        </div>
      </template>

      <template #chip="slotProps">
        <div
          v-if="!!relatedItemsMap[slotProps.value] && !!slotProps.value"
          class="flex items-center justify-between p-1"
        >
          <RenderTemplate
            :collectionName="relatedCollection.id"
            :item="relatedItemsMap[slotProps.value]"
            :fieldInfo="props.column.fieldInfo"
            :defaultTemplate="defaultDisplayTemplate"
          />
        </div>

        <template v-else>
          {{ slotProps.value }}
        </template>
      </template>

      <template #option="slotProps">
        <RenderTemplate
          v-if="!!relatedItemsMap[slotProps.option.value]"
          :collectionName="relatedCollection.id"
          :item="relatedItemsMap[slotProps.option.value]"
          :fieldInfo="props.column.fieldInfo"
          :defaultTemplate="defaultDisplayTemplate"
        />

        <template v-else>
          {{ slotProps.option.label }}
        </template>
      </template>
    </MultiSelect>

    <PMessage v-else>
      {{ $t("field_relationship_not_setup") }}
    </PMessage>
  </div>
</template>

<script setup lang="ts">
interface _VTI_TYPE_DataTableFilterMetaData {
    /**
     * Filter value
     */
    value: any;
    /**
     * Filter match mode
     */
    matchMode: 'startsWith' | 'contains' | 'notContains' | 'endsWith' | 'equals' | 'notEquals' | 'in' | 'lt' | 'lte' | 'gt' | 'gte' | 'between' | 'dateIs' | 'dateIsNot' | 'dateBefore' | 'dateAfter' | string | undefined;
}
type _VTI_TYPE_Column = {
  name: string;
  fieldInfo: FieldInfoInterface;
}
interface ColumnFilterEmits {
  (e: "update:filter", payload: UpdateFilterEmitPayload): void;
}
interface ColumnFilterProps {
  collectionName: string;
  column: _VTI_TYPE_Column;
  filter: _VTI_TYPE_DataTableFilterMetaData;
  matchMode?: FilterMatchModeOptions[keyof FilterMatchModeOptions];
}
  import { toRef, computed, unref } from 'vue'
  import PMessage from 'primevue/message'
  import { useRelationO2M } from '~/api/relations/composables/useRelationO2M'
  import ItemInterface from '~/api/items/entities/ItemInterface'
  import { useDropdownItemsController } from '~/service/dropdown-items/composables/useDropdownItemsController'
  import MultiSelect from '~/components/MultiSelect/MultiSelect.vue'
  import { getFieldLabel } from '~/entities/field'
  import { RenderTemplate } from '~/entities/render-template'
  import { ColumnFilterMeta } from '../../lib/interfaces'
  const props = defineProps<ColumnFilterProps>();
  const emit = defineEmits<ColumnFilterEmits>();
  const collectionName = toRef(props.collectionName);
  const fieldName = toRef(props.column.fieldInfo.name);
  const { relationInfo, relatedCollection } = useRelationO2M(collectionName, fieldName);
  const filterField = computed<string>(() => {
    return props.column.fieldInfo.name;
  });
  type LinearModelValue = (string | number)[];
  const onUpdateModelValue = (event: LinearModelValue): void => {
    emit("update:filter", {
      filterName: props.column.name,
      data: event,
      filterMeta: {
        [ColumnFilterMeta.API_FILTER_NAME]: unref(filterField),
      },
      immediate: true,
    });
  };
  const {
    items: relatedItems,
    options,
    search,
    isLoading: isRelatedItemsLoading,
    virtualScrollerOptions,
    searchEventListeners,
    dropdownEventListeners,
  } = useDropdownItemsController(
    computed(() => relatedCollection.value ?? null),
    computed(() => props.column.fieldInfo),
  );
  const relatedItemsMap = computed<Record<ItemInterface["id"], ItemInterface>>(() =>
    relatedItems.value.reduce<Record<ItemInterface["id"], ItemInterface>>(
      (hashMap, item) => {
        if (item.id in hashMap) return hashMap;
        hashMap[item.id] = item;
        return hashMap;
      },
      {},
    ),
  );
  const defaultDisplayTemplate = computed<string>(() => {
    const fieldTemplate = props.column.fieldInfo.meta.displayOptions?.template;
    if (!!fieldTemplate) return fieldTemplate;
    const collectionTemplate = relatedCollection.value?.meta?.displayTemplate;
    const primaryField = relatedCollection.value?.getPrimaryFieldInfo();
    return collectionTemplate || `{{ ${primaryField!.name} }}`;
  });
</script>

<style scoped></style>

